export default [
    {
        name: "Jordan Danov", certs: [
            { certNumber: "16D69013D447D542C2257DFF0046A496", description: "2015 Premium and Gold Partner Certification" },
            { certNumber: "93E3401970241C04C2257A7D0048D2C8", description: "IBM Cognos Midmarket Foundational Solution Sales Professional v1" },
            { certNumber: "6525415776E61EA9C22575A7004933A1", description: "CompTIA Server+ Certified Professional" },
            { certNumber: "A2D2F159C7BB02C2C2257DFF0046EE47", description: "First Look ThinkCentre M73  M73z PTCW171-R1" },
            { certNumber: "C7B4410A786C495CC2257DFF004682E9", description: "First Look ThinkCentre M83z PTCW00A-R1" },
            { certNumber: "83BE18D86D2D77ABC2257E1A002F4DF5", description: "HP Sales Certified  Converged Infrastructure Solutions 2014" },
            { certNumber: "FCE6D5D8CECA056FC2257E1A002F9D53", description: "HP Sales Certified  Introduction to Selling ServersStorageNetworking and Services" },
            { certNumber: "6020627B509019A2C2257A7D00476F21", description: "IBM Certified Specialist - Storage Sales V2" }
        ]
    },
    {
        name: "Ivan Krustev", certs: [
            { certNumber: "366771CFC3DA7435C2257CFD00289985", description: "IBM Certified Sales Specialist - Power Systems with POWER7 and AIX  Linux -v2" },
            { certNumber: "A1337BD88E352747C22575DD002A6CBD", description: "eServer iSeries Solution Sales V5R2" },
            { certNumber: "C8AB86F67EC37C82C22575A70048B61F", description: "eServer p5 Solution Sales" },
            { certNumber: "18811B4C535B86D5C22575A700486A81", description: "eServer zSeries Sales V1" },
            { certNumber: "953194B0953B14F6C2257F5D00424720", description: "HPE Selling Enterprise Solutions" },
            { certNumber: "D638E7C81024C0DDC2257CFD002952D5", description: "IBM Certified Sales Expert - Power Systems with POWER7 -v2" },
            { certNumber: "0665BBDD6F4BAE6CC2257CFD002926DC", description: "IBM Certified Sales Specialist - Power Systems with POWER7 and IBM i -v2" },
            { certNumber: "CC31473FEBFF0AC3C2257F5D0042021B", description: "IBM z Systems Solutions Sales V7" },
            { certNumber: "911E7C3664A70F1AC22575DD00298FAC", description: "Power Systems Sales for IBM i Operating System" },
            { certNumber: "206B6D3FA0809DB6C22575A700488AE2", description: "RS6000 Solution Sales" },
            { certNumber: "04FFC5926EA10AD7C22575DD002B414F", description: "Storage Sales" },
            { certNumber: "C78A1FE9F349506FC22575A70048F7A6", description: "Storage Sales Version 7" },
            { certNumber: "8C8167E874CF0E66C22575A700489E86", description: "System p Solution Sales V5.3" }
        ]
    },
    {
        name: "Veselka Petrova", certs: [
            { certNumber: "2564CD638970DC31C22576CB003C4F74", description: "Selling Accessories and Upgrades" },
            { certNumber: "644E1BFF71B251AFC2257FE6003A83E8", description: "2016 Premium and Gold Partner Certification" },
            { certNumber: "00F9EC10329BBE5AC2258216003CC86B", description: "Family Introduction - Mission Critical Course" },
            { certNumber: "38DEE84FF1BF1CA0C2258216003BA432", description: "Family Introduction Blade Course" },
            { certNumber: "360A6218451FC1F7C2258216003CD2E8", description: "Family Introduction Dense Course" },
            { certNumber: "FF7D31BBE8BD7516C2257FE6003A60A7", description: "Family Introduction Networking" },
            { certNumber: "389593BF1ADF65EDC2258216003B9942", description: "Family Introduction Rack and Tower Course" },
            { certNumber: "C35668CBE1147CCAC2257FE6003A730F", description: "Family Introduction Storage" },
            { certNumber: "69DA1D8535C214D5C22582160035371F", description: "Family Portfolio Storage V2 Course" },
            { certNumber: "894E4F2DB822686AC22582160049159D", description: "Family Portfolio Dense Course" },
            { certNumber: "2CBA76FF23CCFB39C225821600490B61", description: "Family Portfolio Mission Critical Course" },
            { certNumber: "A7EBE99B3A6EFDA8C225821600352C8B", description: "Family Portfolio Networking Course" },
            { certNumber: "01E71AA8C78250B9C2257CB700320BB9", description: "First Look ThinkCentre M93 M93p and M93z" },
            { certNumber: "FB41DEBAE989C194C2257CB70025E2D7", description: "First Look ThinkPad S440" },
            { certNumber: "B8B73E238BD1DCCAC2257CB6004B1F1C", description: "First Look ThinkPad T440 and T440p" },
            { certNumber: "FB7DB1AE1641D4BCC2257CB70025C749", description: "First Look ThinkPad X240" },
            { certNumber: "BF3A49D54AEFA861C2257CB6004AF364", description: "First Look ThinkPad Yoga" },
            { certNumber: "11132AB968286F21C225804F0020A388", description: "Hitachi Data Systems Qualified Proffesional Sales Foundation Certificate" },
            { certNumber: "973A71D8252B28F7C225805600214AC7", description: "Hoitachi Data Systems Quilified Proffesional Pre-sales Foundation Certificate" },
            { certNumber: "467C73E59C85B478C22578650020155B", description: "IBM Certified Sales Expert - Power Systems with POWER7 - v1" },
            { certNumber: "B50C57DD21C89E27C2257C2A002C5188", description: "IBM Certified Sales Expert - Power Systems with POWER7 -v2" },
            { certNumber: "EAF499F3BEE216AFC225782D002C1318", description: "IBM Certified Sales Specialist - Power systems with Power 7 and IBM i- v1" },
            { certNumber: "93D0FCF070A95667C2257865001FFCF0", description: "IBM Certified Sales Specialist - Power Systems with POWER7 and AIX - v1" },
            { certNumber: "9D15F4736DF50015C2257C1B0032283A", description: "IBM Certified Sales Specialist - Power Systems with POWER7 and AIX  Linux -v2" },
            { certNumber: "6B56CF74796B2C4FC2257C2A002C40F3", description: "IBM Certified Sales Specialist - Power Systems with POWER7 and IBM i -v2" },
            { certNumber: "C4275E448026E636C2257F50003773D4", description: "IBM Certified Sales Specialist Power systems with POWER8 V1" },
            { certNumber: "D49B9F61828F301EC2257C92006F14EF", description: "IBM Certified Solution Advisor - Tivoli Storage Solutions V3" },
            { certNumber: "5CD0A85B46F1ECF7C2257619002793E1", description: "IBM Certified Specialist - IBM Storage Sales Version 9" },
            { certNumber: "3D8BAC9D66DDC5D9C2257822002CAFB7", description: "IBM Certified Specialist - System z Solution Sales V4" },
            { certNumber: "131672ADE9A4F4A3C2257F3E0030EC35", description: "IBM Certified Specialist Midrange Storage Sales V2" },
            { certNumber: "B246E8366BDAA073C2257995002759EC", description: "IBM Certified Specilaist System z Sales V6" },
            { certNumber: "3467F0176E4B7C33C2257C2B00352CDB", description: "IBM Lotus Notes  Domino Sales Mastery Test v2" },
            { certNumber: "19EBB487CC29E7F5C22575A50040EEFD", description: "IBM Storage Sales Version 8" },
            { certNumber: "58C901322EFC0B88C22575E4001FFDAD", description: "IBM System z Solution Sales V3" },
            { certNumber: "71ABDE7A64A3A023C2257FE6003A50F4", description: "Introduction to Lenovo for Enterprise" },
            { certNumber: "F78B68512A0F80C5C2257CB7003273E6", description: "Lenovo 2014 EMEA Premium and Gold Certification" },
            { certNumber: "2C7063FABE8479A2C22575A500421723", description: "Lenovo Think Vantage Technologies and Intel Centrino Pro Processor Technology" },
            { certNumber: "D029A18202135058C22578C40020A082", description: "Lotus Notes and Domino Solution Sales Professional V1" },
            { certNumber: "5BBC69C06BA512EDC22575DF00227AE8", description: "Microsoft Office Ready" },
            { certNumber: "9259E1D28FF66BC9C22575CC004966F0", description: "Power Systems Sales for AIX  Linux" },
            { certNumber: "7BEA1986BE533FB1C22575A50042603A", description: "Selling Lenovo 3000 Systems" },
            { certNumber: "951C428567F0B068C22575DF0022C216", description: "Selling Lenovo Think-Branded Systems" },
            { certNumber: "40B76BCB33ACB007C22575A5004194F3", description: "Selling Lenovo ThinkPad and ThinkCentre Systems" },
            { certNumber: "8A268CE6639376AAC22576CD0033BD5D", description: "Selling ThinkServer" },
            { certNumber: "52A53A86335D88C4C22575DF0022E57E", description: "Selling ThinkServer Test" },
            { certNumber: "1216F90926F7ACC9C22575DF00230207", description: "Selling ThinkServer Why Lenovo BP" },
            { certNumber: "4EB5F5D46D3FB2DBC22576CD0036A559", description: "Selling ThinkStation Systems" },
            { certNumber: "D85C8990F54DFAC3C22575DF002321B9", description: "Selling ThinkVision Monitors" },
            { certNumber: "A47858D642AF7964C22576CD0039CD2C", description: "Selling ThinkVision Monitors" },
            { certNumber: "0DF2DD4911D05C7EC225772800327E0D", description: "Selling ThinkVision Monitors Testing" },
            { certNumber: "638C163F536DD3BDC22582160034FE8F", description: "Simplified Support Services Course Course" },
            { certNumber: "78638F91876DD2C5C22575A500412E53", description: "System z Sales V2" },
            { certNumber: "94425C0E5D37433AC2257FE600386822", description: "The Past Present and Future" },
            { certNumber: "89D368F2E275EAC2C22576CB003C4F72", description: "ThinkCentre M90M90p" },
            { certNumber: "137A07ECECE5FBE8C22576CB003C4F73", description: "ThinkPad T Series  Powered by the All New 2010 Intel Core Processor Family" },
            { certNumber: "4DE9F3CB75368ED9C225821600351549", description: "ThinkSystem High Volume Rack and Tower Course" },
            { certNumber: "61DF9183802EA7DAC22576E0004A6CA8", description: "VMware Sales Professional 4 VSP 4" },
            { certNumber: "E71E12052B70108FC22579AD0048BBEB", description: "VMware Sales Proffesional" }
        ]
    },
    {
        name: "Andrey Chakalov", certs: [
            { certNumber: "D112972F4DCDEB2EC2257CBC00282D42", description: "2014 EMEA Premium and Gold Certification" },
            { certNumber: "7C37803522EC3A18C22580D700363050", description: "2016 Premium and Gold Partner Certification" },
            { certNumber: "D573FB05C7F09F60C2257CBC00297A6B", description: "First Look ThinkCentre E93 and E93z" },
            { certNumber: "BA738F086F463DB6C2257CBC0028E00C", description: "First Look ThinkPad T440 and T440p" },
            { certNumber: "4C927E900E662904C2257CBC0028FEC8", description: "First Look ThinkPad X240" },
            { certNumber: "A184B4BBA6744FC5C2257CBC0028B8C5", description: "First Look ThinkPad Yoga" },
            { certNumber: "7E01CACCC139A08BC2257CBC0029565A", description: "First Look ThinkServer TS140 and TS440" },
            { certNumber: "2EC53C8669369F36C2257E6E00414266", description: "IBM Certified Sales Specialist - Power Systems with POWER8 V1" },
            { certNumber: "6503830E727AD349C2257F50003DC86B", description: "IBM Certified Specialist - Enterprise Storage Sales V4" },
            { certNumber: "E0B9F4A8A0AC1415C2257A1A002E9BF5", description: "IBM Certified Specialist - Storage Sales V2" },
            { certNumber: "516F291A9C0A1BF4C2257CBC0026523A", description: "IBM Certified Specialist - System x Server Family Sales V1" },
            { certNumber: "40456AC47B5A6CDAC2257CBC00289A0D", description: "Lenovo Notebook Systems Hardware Service Training Technology and FRU Service Training RTD08" },
            { certNumber: "D5069F08AE109253C2257CBC002A2313", description: "M245 IBM Tivoli Automation Sales Mastery Test v4" },
            { certNumber: "12B671DAB78C48AFC22580D700360A6D", description: "Selling and Differentiation Series ThinkCentre X1" }
        ]
    },
    {
        name: "Ivaylo Tsolov", certs: [
            { certNumber: "98CC08B62C247916C22580D7003533B4", description: "2016 Premium and Gold Partner Certification" },
            { certNumber: "EECB8A47569175FBC2257F53003BF01E", description: "HPE Selling Enterprise Solutions" },
            { certNumber: "FBD4CF5E8F3C874DC22580D700352636", description: "Selling and Differentiation Series ThinkPad X1 Tablet" },
            { certNumber: "EF7DE287D0A4D3B7C22580D7003AE495", description: "Selling HPE SMB Solutions and Services" },
            { certNumber: "428156D4C1A03306C22580D600291120", description: "SMB Specialization for Account Manager" },
            { certNumber: "1DB1721E329EFF54C22580D60029EEA1", description: "SMB Specialization for Engineers" },
            { certNumber: "A91DA597137095D4C22580D70034ED3E", description: "ThinkPad Story - Expanding the X1 Series" }
        ]
    },
    {
        name: "Aleksey Stoinovsky", certs: [
            { certNumber: "25B50E8E5B4056FEC22575A700495E4B", description: "Associate System Administrator - Lotus Notes and Domino 66.5" },
            { certNumber: "98E1D4E3F29F96B4C22575F6001FA574", description: "IBM Certified Advanced Security Professional - Lotus Notes and Domino 8" },
            { certNumber: "FF020912DE8299F9C22575F4001A024E", description: "IBM Certified Advanced System Administrator - Lotus Notes and Domino 8" },
            { certNumber: "81647C33E4CEEEF9C22575F40019A127", description: "IBM Certified Associate System Administrator - Lotus Notes and Domino 8" },
            { certNumber: "A5D6BDD33B0900BFC22575F2002201B4", description: "IBM Certified Specialist - Open Systems Storage Solutions Version 6" },
            { certNumber: "D48C4C81EC2120FAC22575F200222CEC", description: "IBM Certified Specialist - Storage Networking Solutions Version 3" },
            { certNumber: "B5D3B474B013E38AC22576D9004F2E55", description: "IBM Certified Specialist - Storage Networking Solutions Version 4" },
            { certNumber: "63A87A9DAC90FB8EC22575FC007E3EA8", description: "IBM Certified Specialist - System p Administration" },
            { certNumber: "EE68F809A44102E3C225783F003C5BF2", description: "IBM Certified Specialist - XIV Storage System Technical Solutions Version 2" },
            { certNumber: "353D288C143B4889C225783F003BA309", description: "IBM Certified System Administrator - AIX 6.1" },
            { certNumber: "8B05C55F5CB1BF9BC2257AFC002E5A94", description: "IBM Certified System Administrator - AIX 7" },
            { certNumber: "919A75C932418E29C22575F40019D911", description: "IBM Certified System Administrator - Lotus Notes and Domino 8" },
            { certNumber: "3F53A7528244B29CC225783F003C3B63", description: "IBM Certified Systems Expert - Enterprise Technical Support for AIX and Linux" },
            { certNumber: "2B2B6C99CDF0B747C225783F003C0B6D", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER7 and\nAIX - v1" },
            { certNumber: "50B34D89EF2E1753C2257E690024D641", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER8 Scale-out V1" },
            { certNumber: "4D1338447E9C4948C22575A70049C225", description: "System Administrator - Lotus Notes and Domino 66.5" }
        ]
    },
    {
        name: "Nikolay Georgiev", certs: [
            { certNumber: "90E2F3406DCA7408C22575A7003ED159", description: "Associate System Administrator - Lotus Notes and Domino 8" },
            { certNumber: "F32B04128F9DD8A8C22575A700443E43", description: "BladeCenter Fundamentals" },
            { certNumber: "745E8A5555BE660AC22575A70043E8FC", description: "CompTIA Server+ Certified Professional" },
            { certNumber: "DD6DCACA29FE30AFC22575A700438215", description: "Desktop Systems" },
            { certNumber: "59EB64CAA3F82297C22575A7004423A8", description: "IBM BladeCenter Hands-on Workshop" },
            { certNumber: "A341D80965673613C22576820032B67A", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V5.5" },
            { certNumber: "EA80314570B52761C2257A4D00325277", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V6.2" },
            { certNumber: "9FADAD0BF86365C2C2257912002B58C9", description: "IBM Certified Solution Advisor - Tivoli Service Delivery and Process Automation Solutions V2" },
            { certNumber: "C6C32FD67242E4EAC22578680020E6D4", description: "IBM Certified Specialist - Midrange Storage Technical Support V2" },
            { certNumber: "8A686182AEFC44D2C22578400034A926", description: "IBM Certified Specialist - XIV Storage System Technical Solutions Version 2" },
            { certNumber: "3DB99EFEBB3FDDB0C22578400035319C", description: "IBM Certified System Administrator - AIX 6.1 Administration" },
            { certNumber: "453099B51D61F0F5C22575D800210BD2", description: "IBM Certified Systems Expert - Enterprise Technical Support for AIX and Linux" },
            { certNumber: "523CD2BDA990D201C22579B300319AF8", description: "IBM Certified Systems Expert - Enterprise Technical Support for AIX and Linux -v2" },
            { certNumber: "C44A6F0D7B366979C22575E40020D967", description: "IBM Certified Systems Expert - Virtualization Technical Support for AIX and Linux" },
            { certNumber: "1D27B52C42F5CBD8C225798700314560", description: "IBM Certify System Administrator Lotus Notes and Domino 8" },
            { certNumber: "B94BBDBFB5AC70D5C22575A70043B46B", description: "IBM PC Basics" },
            { certNumber: "BDA5436AFD45E83AC22575A700432729", description: "Introduction to ThinkVantage Technologies Migration and Recovery" },
            { certNumber: "B449FC3DAD57B522C22575A7004311C1", description: "Introduction to ThinkVantage Technologies Security" },
            { certNumber: "79A66F6D49A5C5FDC22575A7004339AC", description: "Introduction to ThinkVantage Technologies System Utilities" },
            { certNumber: "9318C7E4FAD414E8C22575A70042EDDC", description: "Introduction to ThinkVantage Technologies Wireless" },
            { certNumber: "84FFCF40F0F4D635C22575A700435087", description: "Lenovo PC Architecture" },
            { certNumber: "D24FDBC5F6C5CADAC22575A700439805", description: "Notebook Systems" },
            { certNumber: "3988F20039DC969AC22575A700447888", description: "Servicing IBM System x Servers Part II" },
            { certNumber: "146F0A4547AA2778C22575A700446600", description: "Servicing xSeries Servers  Part I" },
            { certNumber: "87AFFB7C1FE310A4C22575A7003F3B59", description: "System p Administration" },
            { certNumber: "38E2B0C129ED57FBC225768200322529", description: "System Storage Implementation for EntryMidrange DS Series v1" },
            { certNumber: "60023FDBFBE01903C22575A7003F0E79", description: "Tivoli Storage Manager V5.4" }
        ]

    },
    {
        name: "Nikolay Zets", certs: [
            { certNumber: "CDA402C0B7B60121C22575A70046FFAA", description: "BladeCenter Fundamentals" },
            { certNumber: "D39D9D3C3DB68978C2257B2C00496FD1", description: "BladeCenter Fundamentals Test 2" },
            { certNumber: "30CC3AE7298E85E7C2257B2C0049B49B", description: "BladeCenter Workshop" },
            { certNumber: "C1A853B81ADF4CB6C22575A600240C43", description: "CompTIA A+ Certified Professional" },
            { certNumber: "214672D2B93276C5C22575A600242575", description: "CompTIA Server+ Certified Professional" },
            { certNumber: "55C12C984CA937A1C22575A7001FC4E0", description: "Desktop Systems" },
            { certNumber: "749C398BBA1D54E9C22575A70047139D", description: "IBM BladeCenter Hands-on Workshop" },
            { certNumber: "DAB92FF422FFCBBBC22575A70047202C", description: "IBM BladeCenter Hands-on Workshop" },
            { certNumber: "EA338CC423E66958C22575DE004A4CB4", description: "IBM Certified Specialist - Power Systems Technical Support for i" },
            { certNumber: "BC65B00F8515CB9DC22575DE004A87F0", description: "IBM Certified Specialist - System z Technical Support V3" },
            { certNumber: "BF03CD326FC45689C225781E003150E9", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER7 and IBM i - v1" },
            { certNumber: "AB7AFBD0DA331D0AC2257C37002C84AE", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER7 and IBM i -v2" },
            { certNumber: "5F87F2DF4885B5A3C22575A6003C3F5C", description: "IBM eServer zSeries Technical V1" },
            { certNumber: "8030517344ED5905C22575A70044C9CD", description: "IBM System z Technical Support V2" },
            { certNumber: "571B13BAD92B8CD1C22575A70020FA73", description: "Introduction to ThinkVantage Technologies Migration and Recovery" },
            { certNumber: "EA5D38E0D54732A5C22575A70020CAE1", description: "Introduction to ThinkVantage Technologies Security" },
            { certNumber: "F8CAD68035623A8FC22575A700210FAC", description: "Introduction to ThinkVantage Technologies System Utilities" },
            { certNumber: "1318058DDAC7B2DDC22575A70020E974", description: "Introduction to ThinkVantage Technologies Wireless" },
            { certNumber: "505C88912B9AE3E5C22575A70044F750", description: "iSeries Technical Solutions Design V5 R2" },
            { certNumber: "B053B656F70AAD4AC2257B2C0042C428", description: "Lenovo Desktop Systems Hardware Service Training Best Practices" },
            { certNumber: "2300AC467700E4DDC2257B2C004364A2", description: "Lenovo Desktop Systems Hardware Service Training Technology and FRU Service" },
            { certNumber: "0E97C9F88D11A486C2257B2C004322FE", description: "Lenovo Notebook Systems Hardware Service Training Best Practices" },
            { certNumber: "7662A47D2473CA1BC2257B2C004375A0", description: "Lenovo Notebook Systems Hardware Service Training Technology and FRU Service" },
            { certNumber: "28BC5DB759628377C22575A70020A05E", description: "Lenovo PC Architecture" },
            { certNumber: "E0F0D074D03CCDA1C22575A70020311F", description: "Lenovo ThinkServer Systems Service Training" },
            { certNumber: "180488207BD0E99AC22575A7002006CA", description: "Notebook Systems" },
            { certNumber: "896AB65AE308DAF2C2257B2C0049EC4C", description: "Servicing IBM System Storage DS300 Family Test" },
            { certNumber: "544AE5A414BE07BFC22575A70046D5B0", description: "Servicing IBM System x Servers Part II" },
            { certNumber: "CA58F9439BADEE5DC2257B2C004900A3", description: "Servicing IBM System x Servers Test - Part II" },
            { certNumber: "A340EF42317E299BC22580D500324469", description: "Servicing NeXtScale Systems" },
            { certNumber: "97124866A7ABBAD4C22580D500326541", description: "Servicing System x BladeCenter" },
            { certNumber: "993058FDA2D82CF4C22580D5003165F9", description: "Servicing System x Flex System" },
            { certNumber: "550CC8B382F8EF32C22580D5003217D9", description: "Servicing System x High End Servers" },
            { certNumber: "20B027EEEAEFD8B6C22580D50032868D", description: "Servicing System x iDataPlex Servers" },
            { certNumber: "EFC913ADDAD5DCD7C22580D50032A92D", description: "Servicing System x Low End Servers" },
            { certNumber: "8C90451BE5F664CBC22580D50032CB4C", description: "Servicing System x Servers and Options" },
            { certNumber: "152DFA4BEE745B64C2257B2C0048ABDE", description: "Servicing the IBM System x Servers - Part I" },
            { certNumber: "AAE2C0455994415EC22575A700468FCE", description: "Servicing xSeries Servers  Part I" },
            { certNumber: "98511D6CD5BCE061C22575A70044E345", description: "System i Technical Solutions  Design including i5OS V5R4" },
            { certNumber: "A7722F89DBA7DC6BC2257B2C00434012", description: "ThinkPad Tablet Systems Hardware Service Training" },
            { certNumber: "90B922D41B7B83E0C22575A700205180", description: "ThinkServer Installation Setup and Configuration" },
            { certNumber: "722FC8A6E49611F2C2257B2C0043977A", description: "ThinkServer Warranty Authorization Course" },
            { certNumber: "BF8109D6E2934043C22580D5002EAF2D", description: "Warranty Service Authorization" }
        ]
    },
    {
        name: "Vladimir Terziyski", certs: [
            { certNumber: "7477B1780343468AC22575A50026E99A", description: "BladeCenter Fundamentals R2" },
            { certNumber: "CE6F744B3C5D08ABC22575D9003E3A3A", description: "BladeCenter Fundamentals R3" },
            { certNumber: "BBAF2D38988DFFD2C22576B90035E5FC", description: "BladeCenter Workshop Test 1" },
            { certNumber: "F77423AEC607161DC22575A5002CA8D9", description: "CompTIA A+ Certified Professional" },
            { certNumber: "1C90D42D8636197BC22575A5002C226F", description: "CompTIA Server+ Certified Professional" },
            { certNumber: "A1DDFB630F061218C22575A5002D95AA", description: "Desktop Systems" },
            { certNumber: "7260B9C1FD4F7E92C22575A5002B898D", description: "Fiber Channel for xSeries hands on workshop" },
            { certNumber: "9F47EFD2B02AFEC9C22575A500275FB3", description: "IBM BladeCenter Hands-on Workshop" },
            { certNumber: "F7D4D3086D282D42C22575A50027AE0D", description: "IBM BladeCenter Hands-on Workshop R1" },
            { certNumber: "755D26A917877173C22575DE004A66AA", description: "IBM Certified Specialist - Power Systems Technical Support for i" },
            { certNumber: "BF79CFAAF1529213C22575DE004A720F", description: "IBM Certified Specialist - System z Technical Support V3" },
            { certNumber: "6A3C37305A216D9FC2257995002AA7D0", description: "IBM Certified Specialist - System z Technical Support V5" },
            { certNumber: "1EB763BF56AC8BFFC2257F8500315EC8", description: "IBM Certified Specialist - z Systems Technical Support V6" },
            { certNumber: "07DB9A933938E7C4C2257868002196D5", description: "IBM Certified Systems Expert - System x BladeCenter Technical Support V5" },
            { certNumber: "D75A9E468A9D635EC225781E003A608B", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER7 and IBM i - v1" },
            { certNumber: "EAEEC2B64FA041C4C2257C370028F157", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER7 and IBM i - v2" },
            { certNumber: "C16E76A285A9DC22C22575A20040D4AE", description: "IBM eServer zSeries Technical V1" },
            { certNumber: "5214EB87E246837AC2257B33004EF388", description: "IBM PureFlex Assessment" },
            { certNumber: "68CB0CD0D6A17CBDC22575A200418377", description: "IBM System z Technical Support V2" },
            { certNumber: "AC746BA814888A7EC22575A5002FFF6F", description: "Introduction to ThinkVantage Technologies Migration and Recovery" },
            { certNumber: "858F81A57F5B031BC22575A5002F30DC", description: "Introduction to ThinkVantage Technologies Security" },
            { certNumber: "7E608FAD5180ED5AC22575A500304249", description: "Introduction to ThinkVantage Technologies System Utilities" },
            { certNumber: "92DCDE363A8AB79BC22575A5002F77DB", description: "Introduction to ThinkVantage Technologies Wireless" },
            { certNumber: "641D7BDDAD436A6AC2257B2C003DE9D8", description: "Lenovo Desktop Systems Hardware Service Training" },
            { certNumber: "BBAAA91ED346343BC22576B900368C72", description: "Lenovo Desktop Systems Service Training Course" },
            { certNumber: "8799868862D319B7C2257B2C003DD4D5", description: "Lenovo Notebook Systems Hardware Service Training" },
            { certNumber: "94655338D43A384AC22576B90036282F", description: "Lenovo Notebook Systems Service Training Course" },
            { certNumber: "5CB48E531CC74BF8C22575A5002E1048", description: "Lenovo PC Architecture" },
            { certNumber: "4C95FD29EC72FF98C2257B2C003DA732", description: "Lenovo ThinkServer Systems Hardware Service Training" },
            { certNumber: "65D10BCC6A0E535CC22575A500312B05", description: "Lenovo ThinkServer Systems Service Training" },
            { certNumber: "276A171932497443C22575A5002DE035", description: "Notebook Systems" },
            { certNumber: "22D24C48726AF9ABC22575A5003B91CA", description: "PC Architecture" },
            { certNumber: "8B2093534F627714C2257B3D004AC81A", description: "Servicing IBM PureFlex System" },
            { certNumber: "A2D3E49B3AD7AFCAC22576B900358A98", description: "Servicing IBM System Storage DS300 Family Test" },
            { certNumber: "F920A0D25AA38AE5C22575B00037858A", description: "Servicing IBM System x Servers Part II Test" },
            { certNumber: "5630841B06DB6B99C2257EA5002CA284", description: "Servicing NeXtScale Systems" },
            { certNumber: "0944C1A031527452C2257E9E003A19A8", description: "Servicing System x BladeCenter" },
            { certNumber: "4D063B388F834875C2257EB2004D9937", description: "Servicing System x Flex System" },
            { certNumber: "9D072F71BFB90D82C2257EAB0024019C", description: "Servicing System x High End Servers" },
            { certNumber: "EE58F4917943C503C2257EA60022F5CE", description: "Servicing System x iDataPlex Servers" },
            { certNumber: "49A148C9F2119D27C2257EA600309B35", description: "Servicing System x Low End Servers" },
            { certNumber: "595C3A4C74D0E266C2257EA6003121E3", description: "Servicing System x Servers and Options" },
            { certNumber: "D0AFC92E2DA6CE78C22578570037DC10", description: "Servicing the IBM System x Servers Part 1 Release 4.00" },
            { certNumber: "1F42463DECFECED6C22575A5002ADAA6", description: "Servicing xSeries Servers  Part I" },
            { certNumber: "615E7A7962DB3DF6C22575A20041AFCD", description: "System i Technical Solutions  Design including i5OS V5R4" },
            { certNumber: "B29203BA74A55722C22578680020FD4C", description: "System x Technical Principles V10" },
            { certNumber: "A0D5CA7E4FAFEE72C2257B2C003DFF1C", description: "ThinkPad Tablet Systems Hardware Service Training" },
            { certNumber: "31AAC69074DB89FAC22575A50030DC5B", description: "ThinkServer Installation Setup and Configuration" },
            { certNumber: "C5FBC2A3D76E3608C22575A500309516", description: "Warranty Basics for Administrators" },
            { certNumber: "9D25090183E35078C22575A50030AECD", description: "Warranty Basics for Technicians" },
            { certNumber: "86A4D8B5E9D86435C22580A0004DB38C", description: "Warranty Service Authorization Exam" }
        ]
    },
    {
        name: "Liubomir Djantov", certs: [
            { certNumber: "AA5D17AD7E840D80C22578A9003DCB62", description: "CompTIA A+ Certified Professional" },
            { certNumber: "705CAC5EC0A5EA62C2257E7B003B2EF3", description: "CompTIA Server+ Certified Professional" },
            { certNumber: "710B59A23B8BAC43C2257F5400247C37", description: "HPE 3PAR SA ENABLEMENT WORKSHOP" },
            { certNumber: "5B6EFB78A93CCC4AC2257B3D004ABF1F", description: "IBM PureFlex Assessment" },
            { certNumber: "4C44D0985F311450C225825E0045A877", description: "Lenovo Certified Data Center Technical Sales Professional" },
            { certNumber: "8440D52541FF7A50C2258550006E5045", description: "Lenovo Certified Data Center Technical Sales Professional" },
            { certNumber: "07A3D8489EAB8031C2257AB70048B346", description: "Lenovo Desktop Systems Hardware Service Training Technology and FRU Service" },
            { certNumber: "BAC4B2FCC9F99F4BC2257AB700492624", description: "Lenovo Notebook Systems Hardware Service Training Technology and FRU Service" },
            { certNumber: "6DBA7A04580926E4C2257A100043BA26", description: "Servicing IBM System x Servers Test - Part II" },
            { certNumber: "39B5F063E638D8D6C22581840061C9D0", description: "Servicing Lenovo Enterprise Products" },
            { certNumber: "147EF08751FEE566C2257EA600322FCB", description: "Servicing System x BladeCenter" },
            { certNumber: "6C6333844FC0AF8DC2257EA60044A6AE", description: "Servicing System x Flex System" },
            { certNumber: "DF156F63A6815ABDC22578B20048814E", description: "Servicing System x High End Servers" },
            { certNumber: "AA03FCBA5B1A2930C2257EA600449353", description: "Servicing System x iDataPlex Servers" },
            { certNumber: "E0784D0AD2CA82DEC2257EA60044D99D", description: "Servicing System x Low End Servers" },
            { certNumber: "739C8923F096F1F9C2257EA6003D39D7", description: "Servicing System x NeXtScale Systems" },
            { certNumber: "29C748A594BDBCD1C2257EA6004477B6", description: "Servicing System x Servers and Options" },
            { certNumber: "63244490FB122239C2257A02004AFA81", description: "Servicing the IBM System x Servers Part 1" },
            { certNumber: "ECF31200ED0F4135C22578AE004D429B", description: "Think Innovation and Quality from Lenovo" },
            { certNumber: "FF9848B689BC1754C2257AB700494CBB", description: "ThinkPad Tablet Systems Hardware Service Training" },
            { certNumber: "70A2129AC9CABD4FC2257ABC003EECC5", description: "ThinkServer Warranty Authorization Course" },
            { certNumber: "815917D3F9BA5645C22580D5002F030B", description: "Warranty Service Authorization Exam" }
        ]
    },
    {
        name: "Stanislav Blagoev", certs:
            [
                { certNumber: "8C68FBE4B4C8ABCBC22575A60027EBBC", description: "Database Administrator - DB2 9 for Linux UNIX and Windows" },
                { certNumber: "A336C3C31F865DE0C22575A600271F0E", description: "DB2 Universal Database V8.1 Family Database Associate" },
                { certNumber: "0C3E67FE7A7116B6C22575A600276338", description: "DB2 Universal Database V8.1 for Linux UNIX and Windows Database Administration" },
                { certNumber: "2DFE4C5EF1384AA4C225774400273C08", description: "IBM Information Management Informix Dynamic Server Technical Professional v2" },
                { certNumber: "426F0651185522E1C225772900232F9C", description: "IBM Tivoli Federated Identity Manager V6.1" },
                { certNumber: "73BD934A8C45928FC2257609002877BB", description: "Power Systems Technical Support for AIX and Linux" },
                { certNumber: "1094A9648B51EFC4C22575A6002603EB", description: "System p Technical Sales Support" },
                { certNumber: "A609D6908925D8AFC22575A6002638EC", description: "Tivoli Monitoring V6.2" }
            ]
    },
    {
        name: "Georgi Chompalov",
        certs:
            [
                { certNumber: "5626C38B880BD93FC22575A200280F30", description: "Enterprise Technical Support for AIX and Linux" },
                { certNumber: "D23DEEA7F5634B5AC22575A4004403FA", description: "eServer p5 and pSeries Administration and Support for AIX 5L V5.3" },
                { certNumber: "3C8391D90F773221C22575A4004318FE", description: "eServer p5 and pSeries Enterprise Technical Support AIX 5L V5.3" },
                { certNumber: "E567DF68F10C4E89C22575C8001FA829", description: "High-End Disk for Open Systems Version 1" },
                { certNumber: "461CC07665298378C22575A40041E302", description: "High-End Tape Solutions Version 5" },
                { certNumber: "F2EA3DD0EB62B5DFC22575A4004291E3", description: "High End Disk Solutions Version 5" },
                { certNumber: "1FC84BB1712128F1C225790A00238A2C", description: "IBM Certified Administrator - Cognos 10 BI" },
                { certNumber: "1BDCA6065D7E1A7BC22575C8001FC90C", description: "IBM Certified Advanced Technical Expert - Power Systems with AIX" },
                { certNumber: "DBD74EAE6EDED2ABC2257A3100207271", description: "IBM Certified Advanced Technical Expert - Power Systems with AIX v2" },
                { certNumber: "2752596CAC8E1593C2257C1D00269A1A", description: "IBM Certified Advanced Technical Expert - Power Systems with AIX v3" },
                { certNumber: "55B3459890CFAB5CC22575A4004477CC", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V5.2" },
                { certNumber: "0E71A4356D91A9C4C22575A40044447F", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V5.3" },
                { certNumber: "5721E04F91791A27C22575D200204858", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V5.4" },
                { certNumber: "CE64E95E686809A1C22575DA00208433", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V5.5" },
                { certNumber: "C589199FF4346FF7C225781F002C709B", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V6.1" },
                { certNumber: "34A05DD4BC1E3822C2257C0E0039BDA0", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V6.2" },
                { certNumber: "B971907A8370EE1DC2257C9300403108", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V6.3" },
                { certNumber: "DBF0AF151030448CC2257F7E00256EA4", description: "IBM Certified Deployment Professional - Tivoli Storage Manager V7.1" },
                { certNumber: "8DF21819D8AC1AA6C2257BC800326F98", description: "IBM Certified Specialist - Enterprise Storage Technical Support V3" },
                { certNumber: "2791CAF80E3063E5C22579990031B001", description: "IBM Certified Specialist - High-End Disk for Open Systems V2" },
                { certNumber: "5A8EC8EE3DCAAFE1C22579A400499412", description: "IBM Certified Specialist - High-End Tape V6" },
                { certNumber: "694B41C55F6D0528C2257F4E0026E65B", description: "IBM Certified Specialist - Midrange Storage Technical Support V4" },
                { certNumber: "1B835A8FFE5A21F8C22578620022C5EC", description: "IBM Certified Specialist - System x Technical Principles V10" },
                { certNumber: "4DA06715F4E8DA8CC2257818002AE116", description: "IBM Certified System Administrator - AIX 6.1 Administration" },
                { certNumber: "CC8E9406A1B0AD9CC2257B0C00290D60", description: "IBM Certified System Administrator - AIX 7" },
                { certNumber: "A634A6F69645E171C22579B100257EDC", description: "IBM Certified Systems Expert - Enterprise Technical Support for AIX and Linux v2" },
                { certNumber: "36769777854B6300C2257B0C0028E17D", description: "IBM Certified Systems Expert - High Availability for AIX Technical Support and Administration -v2" },
                { certNumber: "D01BDE3965C01E85C22578620022EFBD", description: "IBM Certified Systems Expert - System x BladeCenter Technical Support V5" },
                { certNumber: "6BD97CBC9FD0B616C2257C1D002673D1", description: "IBM Certified Systems Expert - Virtualization Technical Support for AIX and Linux - v2" },
                { certNumber: "B9333EFC29D320BFC22578380045D590", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER7 and AIX - v1" },
                { certNumber: "156401B1BCB2FCA4C2257C370026C5FB", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER7 and AIX  Linux -v2" },
                { certNumber: "B00C0A8E471D21F6C2257E76002A7A8C", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER8 Enterprise V1" },
                { certNumber: "EE819FCE7140D8A1C2257E60004B81C2", description: "IBM Certified Technical Sales Specialist - Power Systems with POWER8 Scale-out V1" },
                { certNumber: "D1C494505C7FB5DCC22575DE003F4A23", description: "Power Systems Technical Support for AIX and Linux" },
                { certNumber: "B0FDA2B006230ABFC22575A40042E39B", description: "pSeries Enterprise Technical Support" },
                { certNumber: "808DF6D8368DB2E3C22575A400424F28", description: "System p Administration" },
                { certNumber: "09E1E74523BC0102C22575A40043CE85", description: "System p Technical Sales Support" },
                { certNumber: "DEE93BB3835714D4C22575C400323868", description: "System x BladeCenter Technical Support V4" },
                { certNumber: "3CC4B06125D8BDD5C22575C200302CE9", description: "Virtualization Technical Support for AIX and Linux" }
            ]
    },
    {
        name: "Georgi Stoyanov", certs:
            [
                { certNumber: "E4E2B45C9DEDD38FC225810900289CEB", description: "EntrySan System Authorization" },
                { certNumber: "ED96A677ADB2759DC225825D004BD4C8", description: "Lenovo Data Center Technical Sales Professional" },
                { certNumber: "623E8DCD8384EB45C225810900296DAD", description: "Notebook Tablet Desktop  Workstation Authorized Technician  RAService" },
                { certNumber: "6BD4D78D1ADEC1B8C22581090028E91F", description: "Servicing Lenovo Enterprise Products" },
                { certNumber: "E61ABDA5C96CD12BC225813300236851", description: "Servicing Lenovo S2200S3200 Storage Enclosure and Lenovo" },
                { certNumber: "BAD5B72841B00857C22581090029E176", description: "Servicing System x BladeCenter" },
                { certNumber: "7BD00919866C6A96C225810900283E3D", description: "Servicing System x Flex System" },
                { certNumber: "A4264D02F17B7DAEC2258109002A6576", description: "Servicing System x High End Servers" },
                { certNumber: "6E4AF2ABA1CCB7D2C2258109002A17C1", description: "Servicing System x iDataPlex Servers" },
                { certNumber: "26D1EA1986A0F6A4C2258109002A42F6", description: "Servicing System x Low End Servers" },
                { certNumber: "3FE0AE8CEA6BA981C22581090029A59D", description: "Servicing the IBM NeXtScale nx360 M5 Water Cooled Technology" },
                { certNumber: "7E684713FB95F552C22581330023D2EF", description: "Servicing the IBM NeXtScaleSystem nx360 M5" },
                { certNumber: "9F18CC3BA15BCCC2C22581330024907C", description: "Storage Best Practices" },
                { certNumber: "A40142D87E4E39C9C225813300247559", description: "Storage Technologies" },
                { certNumber: "C3A54678FB28D752C22581090027498A", description: "ThinkServer Basic Skills Authorization" },
                { certNumber: "9BCEC9FCB5676493C22581090027C827", description: "ThinkServer System Authorization." },
                { certNumber: "C92414462A4AA5F6C2258109002C96EB", description: "Warranty Service Authorization Exam" }
            ]
    },
    {
        name: "Krasimir Bangeev", certs: [
            { certNumber: "44B94C59CC6B8CB3C2257F5C002D4CD8", description: "HP OneView 1.20" },
            { certNumber: "86FB1D6F7DCA266FC22579120025994F", description: "IBM Certified Administrator - Cognos 10 BI" },
            { certNumber: "D0A1F6F287D04878C22579DE00277944", description: "IBM Certified Administrator - Tivoli Monitoring V6.2.3" },
            { certNumber: "6A000988DE9EA5E8C225786C0034C806", description: "IBM Certified Deployment Professional - Tivoli Monitoring V 6.2" },
            { certNumber: "4A9EB3DC93C85A72C2257A2500236674", description: "IBM Certified Specialist - Retail Store Solutions 4690 Technical Solutions" },
            { certNumber: "437AB40287C3A66CC2257A2500233949", description: "IBM Certified Specialist - Retail Store Solutions Windows Technical Solutions" }
        ]
    },
    {
        name: "Vencislav Georgiev", certs: [
            { certNumber: "011C26868F0615C5C225786B00330A31", description: "IBM Certified Sales Expert - Power Systems with POWER7 - v1" },
            { certNumber: "D88C28F7EF5B84F7C225786B0032CA5B", description: "IBM Certified Sales Specialist - Power Systems with POWER7 and AIX - v1" },
            { certNumber: "BA618C88633742F8C2257840003C59EB", description: "IBM Certified Sales Specialist - Power Systems with POWER7 and IBM i - v1" },
            { certNumber: "BA38B6EE2DCE83C4C2257612004616F7", description: "IBM Certified Solution Expert - Dynamic Infrastructure Sales Leader" },
            { certNumber: "15BF7B117D63613DC22575DD002A1A9D", description: "IBM Certified Specialist - IBM Storage Sales Version 9" },
            { certNumber: "177C816401883F8DC22575DD0029CDD5", description: "IBM Certified Specialist - Power Systems Sales for AIX and Linux" },
            { certNumber: "8497B83A07CF20BBC22575F6002063DF", description: "IBM Certified Specialist - Power Systems Sales for IBM i Operating System" },
            { certNumber: "87E7EB353EBE8D4DC2257681003DD38B", description: "IBM Certified Specialist - Storage Sales Combined V1" },
            { certNumber: "0AFC58AE2FED80A8C225765B002DC32E", description: "IBM Certified Specialist - Storage Sales for N Series Version 2" },
            { certNumber: "88DB2ECAEC18F8E1C22579A0002B32C9", description: "IBM Certified Specialist - Storage Sales V2" },
            { certNumber: "DC369B46E8ACF0F3C22579AD002802CF", description: "IBM Certified Specialist - System x Sales V6" },
            { certNumber: "9A4042EF5B832DFFC22575E0001FF236", description: "IBM Certified Specialist - System z Sales V3" },
            { certNumber: "ACB1FD69B188945CC2257999002A208D", description: "IBM Certified Specialist - System z Sales V6" },
            { certNumber: "FDB0C999D9B1DD05C2257840003CAADC", description: "IBM Certified Specialist - System z Solution Sales V4" },
            { certNumber: "2F61B40FE3452E51C22579AD00283A78", description: "IBM Certified Systems Expert - System x Sales Expert V3" },
            { certNumber: "FD72543BA67F4F34C22575A6002BEE01", description: "IBM Storage Sales Version 8" },
            { certNumber: "9237D87A8C3F97B7C22575A500428FCF", description: "IBM System p Solution Sales V5.3" },
            { certNumber: "5B4FAE214684A4A9C22575A50042F4B1", description: "Lenovo Think Vantage Technologies and Intel Centrino Pro Processor Technology" },
            { certNumber: "4AF90DB4C5A7E605C22575A60029CC2E", description: "Microsoft Office Ready" },
            { certNumber: "FCA2E2C40508F1F2C22575A500430C6C", description: "Selling Lenovo 3000 Systems" },
            { certNumber: "B8016A51E5AAEE00C22575A50042D619", description: "Selling Lenovo ThinkPad and ThinkCentre Systems" },
            { certNumber: "A1CDE6BFE768D196C22575A6002A0A1D", description: "Selling ThinkServer" },
            { certNumber: "A2455471FAEF6411C22575A6002A30BE", description: "Selling ThinkVision Monitors" },
            { certNumber: "C2FBF8AAE22A2868C22575A6002D2995", description: "Storage Sales for High-End Disk Version 1" },
            { certNumber: "C6118EE144B2A748C22575B00037C24E", description: "Storage Sales for High-End Tape Version 1" },
            { certNumber: "BA3D7B380979486DC22575A6002CE894", description: "Storage Sales for N series Version 1" },
            { certNumber: "47A04E2BB725263EC22575A6002B8D10", description: "System x Sales Expert V1" },
            { certNumber: "640F8B621883A5BBC22575A6002C11D5", description: "System x Sales V5" },
            { certNumber: "A4870229268E5DA0C22575A6002BBAD8", description: "System z Sales V2" },
            { certNumber: "5C7BD7DFD2955D0AC22578B90048926B", description: "Tivoli Automation Solution Sales Professional v3" },
            { certNumber: "BC8474DE19797F93C225765000276BE3", description: "Tivoli Storage Manager Technical Sales Professional v1" }
        ]
    },
    {
        name: "Dobrin Damyanov Danov", certs: [
            { certNumber: "31C9ED33560C5751C22577AF002E2713", description: "IBM Checkout Environment for Consumer-Service  Configurations and Extensions Workshop" },
            { certNumber: "7940B7DAFE258DC5C225772900369478", description: "Retail Store Solutions Sales V3" }
        ]
    },
    {
        name: "Damyan Danov", certs: [
            { certNumber: "850D921C07D789FEC22575A70047FECA", description: "IBM eServer zSeries Sales V1" },
            { certNumber: "8ED2DEC33315BD64C22575A700479C06", description: "IBM System p Solution Sales V5.3" },
            { certNumber: "C84F396870254C8CC22575A700475880", description: "Power Systems Sales for IBM i Operating System" },
            { certNumber: "E60AE8CF360FF41CC22575A70047B38F", description: "RS6000 Solution Sales" }
        ]
    },
    {
        name: "Bulmag AD", certs: [
            { certNumber: "4A7984F69FF3FD27C22575A7002723AB", description: "IBM Partner Certification v.395" },
            { certNumber: "DF42F0DB7E201564C22575DD003AA600", description: "IBM Partner Certification v.400" },
            { certNumber: "EC4284AD42126384C225781600458FFF", description: "IBM Partner Certification v.409" },
            { certNumber: "E56BDB2561C5A5ADC225781F00301684", description: "IBM Partner Certification v.4.2.1" },
            { certNumber: "C191F534B27A8876C225781F002E5028", description: "IBM System x and BladeCenter recommended minimum number of certifications" },
            { certNumber: "88BC1F81F956B988C22575A70028132A", description: "IBM Warranty Service" },
            { certNumber: "C71CA16BECA408AEC225781F002DCF59", description: "IBM Warranty Service" },
            { certNumber: "13CC6DD0ACFDD1E2C22575DD003B7941", description: "ibm_systemx_blade_certs_V100" },
            { certNumber: "8F250BC0DB58CDE6C22575A7003D8698", description: "ISO 90012000 EN ISO 90012000 BS EN ISO 90012000" }
        ]
    },
    {
        name: "Stefan Zets", certs: [
            { certNumber: "0ECBC8CD95C22885C225761F004DFD29", description: "Introduction to ThinkVantage Technologies Migration and Recovery" },
            { certNumber: "6E02D6D2C8E1B6E1C225761F004BEA23", description: "Introduction to ThinkVantage Technologies Security" },
            { certNumber: "90090BF24E7BB5A7C225761F004E385F", description: "Introduction to ThinkVantage Technologies System Utilities" },
            { certNumber: "E1E6EFE77AACCF4DC225761F0049E3DD", description: "Introduction to ThinkVantage Technologies Wireless" },
            { certNumber: "23E97840E462957AC225761F004DA7F2", description: "PC Architecture" }
        ]
    }
]
